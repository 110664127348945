import React, {Fragment} from 'react';
import axios from 'axios';
import PluginList from "./components/PluginList";

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            error: null,
        }
        axios.get('./data.json').then(response => {
            console.log(response.data);
            this.setState({
                data: response.data.map(row => ({
                    name: row.name,
                    firstCheck: new Date(row.first_check),
                    lastCheck: new Date(row.last_check),
                    versions: row.versions.map(version => ({
                        firstSeen: new Date(version.first_seen),
                        firstZipTimestamp: new Date(version.first_zip_timestamp),
                        lastZipTimestamp: new Date(version.last_zip_timestamp),
                        major: version.major,
                        minor: version.minor,
                        patch: version.patch,
                        zipSha256: version.zip_sha256,
                    })),
                }))
            });
        }).catch(error => {
            this.setState({error: error.message});
        });
    }

    render() {
        if (this.state.error !== null) {
            return <div>Error: ${this.state.error}</div>
        } else if (this.state.data === null) {
            return <div>Loading...</div>
        } else {
            let versionCount = 0
            let firstZipTimestamp = null;
            let lastZipTimestamp = null;
            this.state.data.forEach(plugin => {
                plugin.versions.forEach(version => {
                    if (firstZipTimestamp === null || firstZipTimestamp > version.firstZipTimestamp) {
                        firstZipTimestamp = version.firstZipTimestamp;
                    }
                    if (lastZipTimestamp === null || lastZipTimestamp < version.lastZipTimestamp) {
                        lastZipTimestamp = version.lastZipTimestamp;
                    }
                })
                versionCount += plugin.versions.length;
            });
            return <Fragment>
                <p>
                    Scraped {this.state.data.length} plugins in {versionCount} versions.
                    Probably developed
                    between {firstZipTimestamp.toLocaleString()} and {lastZipTimestamp.toLocaleString()}
                </p>
                <PluginList>{this.state.data}</PluginList>
            </Fragment>
        }
    }
}
