import React, {Fragment} from 'react';
import VersionUtils from "../utils/VersionUtils";

export default function PluginList(props) {
    return <table border={1}>
        <thead>
        <tr>
            <th>Plugin Name</th>
            <th>First Seen</th>
            <th>Last Seen</th>
            <th>Versions</th>
        </tr>
        </thead>
        <tbody>
        {props.children.map(row => {
            return <Fragment>
                <tr key={row.name}>
                    <th>{row.name}</th>
                    <td>{new Date(row.firstCheck).toLocaleString()}</td>
                    <td>{new Date(row.lastCheck).toLocaleString()}</td>
                    <td>{row.versions.length}</td>
                </tr>
                {row.versions.map(version => <tr key={row.name + '_' + version.zipSha256}>
                    <td colSpan={4}>
                        <dl>
                            <dt>Version</dt>
                            <dd>{(new VersionUtils()).fromVersionRow(version)}</dd>
                            <dt>ZIP</dt>
                            <dd>{version.zipSha256}</dd>
                            <dt>First Timestamp</dt>
                            <dd>{version.firstZipTimestamp.toLocaleString()}</dd>
                            <dt>Last Timestamp</dt>
                            <dd>{version.lastZipTimestamp.toLocaleString()}</dd>
                        </dl>
                    </td>
                </tr>)}
            </Fragment>
        })}
        </tbody>
    </table>
}
