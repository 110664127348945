export default class VersionUtils {
    fromVersionRow(versionRow) {
        if (versionRow.major && versionRow.minor) {
            if (versionRow.patch) {
                return `${versionRow.major}.${versionRow.minor}.${versionRow.patch}`;
            } else {
                return `${versionRow.major}.${versionRow.minor}`;
            }
        } else {
            return 'None'
        }
    }
}